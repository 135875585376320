import renderHTML from 'react-render-html'

export function getMessage(key, text='') {
    const messages = {
        auctionLoser: `This auction has`,
        auctionWinner: `Congratulations! You won this auction for ${text}`,
        bidPrompt: `Bid before the auction ends!`,
        bidConfirm: `You are about to submit a bid of ${text}. If you win this auction, you are legally obligated to purchase this item. Are you sure you want to continue?`,
        bidHighestBidder: `You are the highest bidder. Your last bid: ${text}`,
        bidInvalid: `Not a valid bid amount.`,
        bidLoginRequired: `To bid you need to be logged in, please login below:`,
        bidMaxInvalid: `Your bid must be higher than your max bid of ${text}`,
        bidMinInvalid: `Not a valid bid amount. Minimum bid required: ${text}`,
        bidOutBid: `You have been outbid. Your last bid: ${text}`,
        dialogShow: `Don't show this again`,
        emptyField: `Cannot be empty`,
        error: `There was an error.`,
        formEmailInvalid: `Invalid email address`,
        formEmailVerify: `Please verify your email address.`,
        formPasswordInvalid: `Password must contain at least 6 characters (to update please visit your <a style="color:white" href="https://www.comicconnect.com/account-settings" title="Change your password" target="_blank">account settings</a> and try again), alternatively please contact us on <a style="color:white" href="mailto:support@comicconnect.com" title="Contact support">support@comicconnect.com</a>`,
        formPasswordWeak: `The password is too weak. (to update please visit your <a style="color:white" href="https://www.comicconnect.com/account-settings" title="Change your password" target="_blank">account settings</a> and try again), alternatively please contact us on <a style="color:white" href="mailto:support@comicconnect.com" title="Contact support">support@comicconnect.com</a>`,
        loginFail: `Your email or password was incorrect, please try again or contact us on <a style="color:white" href="mailto:support@comicconnect.com" title="Contact support">support@comicconnect.com</a>`,
        register: `Please register below:`,
        registerEmailExists: `The email address has already been registered.`,
        registerSuccess: `Logging in...`,
        loginFailIssue: `Login has failed, please make sure your password is at least 6 characters (to update please visit your <a style="color:white" href="https://www.comicconnect.com/account-settings" title="Change your password" target="_blank">account settings</a> and try again), alternatively please contact us on <a style="color:white" href="mailto:support@comicconnect.com" title="Contact support">support@comicconnect.com</a>`,
    }
    return renderHTML(messages[key])
}