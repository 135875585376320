// https://mui.com/customization/theme-components/
import ManropeBoldWoff2 from '../assets/fonts/manrope/bold.woff2'
import ManropeExBoldWoff2 from '../assets/fonts/manrope/extrabold.woff2'
import ManropeExLightWoff2 from '../assets/fonts/manrope/extralight.woff2'
import ManropeLightWoff2 from '../assets/fonts/manrope/light.woff2'
import ManropeMediumWoff2 from '../assets/fonts/manrope/medium.woff2'
import ManropeRegWoff2 from '../assets/fonts/manrope/regular.woff2'
import ManropeSemiBoldWoff2 from '../assets/fonts/manrope/semibold.woff2'
import GothMediumWoff2 from '../assets/fonts/gotham/medium.woff2'
import GothRegWoff2 from '../assets/fonts/gotham/book.woff2'
import GothBoldWoff2 from '../assets/fonts/gotham/bold.woff2'
import GothLightWoff2 from '../assets/fonts/gotham/light.woff2'
import GothExtraLightWoff2 from '../assets/fonts/gotham/extralight.woff2'


const components = {
    MuiButton: {
        variants: [
            {
                props: { variant: 'primary' },
                style: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.blue.main,
                    backgroundImage: 'linear-gradient(0deg, hsl(230deg 75% 51%) 0%, hsl(224deg 83% 49%) 11%, hsl(218deg 100% 46%) 22%, hsl(216deg 100% 47%) 33%, hsl(215deg 100% 47%) 44%, hsl(213deg 100% 48%) 56%, hsl(211deg 100% 49%) 67%, hsl(210deg 100% 49%) 78%, hsl(208deg 100% 50%) 89%, hsl(207deg 100% 50%) 100%)',
                    borderRadius: 25,
                    borderBottomColor: theme.palette.blue.main,
                    borderLeftColor: 'transparent',
                    borderRightColor: 'transparent',
                    borderStyle: 'solid',
                    borderTopColor: theme.palette.blue.main,
                    borderWidth: '1px',
                    color: theme.palette.white.main,
                    cursor: 'pointer',
                    filter: 'drop-shadow(0 2px 4px rgb(0 0 0 / 33%))',
                    fontSize: '14px',
                    padding: '7px 25px',
                    textShadow: 'hsl(0deg 0% 0% / 50%) 0 1px 1px',
                    ':hover': {
                        boxShadow: '0 1px 1.1px hsl(0deg 0% 0% / 48%), 0.1px 5.2px 5.9px -1.2px hsl(0deg 0% 0% / 48%), 0.2px 20px 22.5px -2.5px hsl(0deg 0% 0% / 48%)',
                        transitionDuration: '200ms',
                    },
                }),
            },
            {
                props: { variant: 'primaryAlt' },
                style: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.orange.main,
                    backgroundImage: 'linear-gradient(0deg,#e65000,#eb5a00 11%,#eb5e00 22%,#f06800 33%,#f06c00 44%,#f57600 56%,#f57a00 67%,#f57e00 78%,#fa8500 89%,#fa8e00)',
                    borderRadius: 25,
                    borderBottomColor: theme.palette.orange.main,
                    borderLeftColor: 'transparent',
                    borderRightColor: 'transparent',
                    borderStyle: 'solid',
                    borderTopColor: theme.palette.orange.main,
                    borderWidth: '1px',
                    color: theme.palette.white.main,
                    cursor: 'pointer',
                    filter: 'drop-shadow(0 2px 4px rgb(0 0 0 / 33%))',
                    fontSize: '14px',
                    padding: '7px 25px',
                    textShadow: 'hsl(0deg 0% 0% / 50%) 0 1px 1px',
                    ':hover': {
                        boxShadow: '0 1px 1.1px hsl(0deg 0% 0% / 48%), 0.1px 5.2px 5.9px -1.2px hsl(0deg 0% 0% / 48%), 0.2px 20px 22.5px -2.5px hsl(0deg 0% 0% / 48%)',
                        transitionDuration: '200ms',
                    },
                }),
            },
        ],
        styleOverrides: {
            outlined: ({ ownerState, theme }) => ({
                color: theme.palette.white.main,
                borderColor: theme.palette.grey.button,
                borderRadius: '10px',
                textTransform: 'none',
                fontSize: theme.spacing(2),
                padding: '8px 10px',
                lineHeight: theme.spacing(3),
                '&:hover': {
                    borderColor: theme.palette.grey.button,
                    backgroundColor: theme.palette.grey.menu,
                },
            }),
        },
    },
    MuiCssBaseline: {
        // TODO: Add in the rest of the fonts
        styleOverrides: `
            @font-face {
            font-family: 'ManropeBold';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: url(${ManropeBoldWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'ManropeExBold';
                font-style: normal;
                font-display: swap;
                font-weight: 800;
                src: url(${ManropeExBoldWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'ManropeExLight';
                font-style: normal;
                font-display: swap;
                font-weight: 200;
                src: url(${ManropeExLightWoff2}) format('woff2');
                }
            @font-face {
                font-family: 'ManropeLight';
                font-style: normal;
                font-display: swap;
                font-weight: 300;
                src: url(${ManropeLightWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'ManropeMedium';
                font-style: normal;
                font-display: swap;
                font-weight: 500;
                src: url(${ManropeMediumWoff2}) format('woff2');
                }
            @font-face {
                font-family: 'ManropeReg';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: url(${ManropeRegWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'ManropeSemiBold';
                font-style: normal;
                font-display: swap;
                font-weight: 600;
                src: url(${ManropeSemiBoldWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'GothamMedium';
                font-style: normal;
                font-display: swap;
                font-weight: 500;
                src: url(${GothMediumWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'GothamBold';
                font-style: normal;
                font-display: swap;
                font-weight: 800;
                src: url(${GothBoldWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'GothamRegular';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: url(${GothRegWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'GothamLight';
                font-style: normal;
                font-display: swap;
                font-weight: 300;
                src: url(${GothLightWoff2}) format('woff2');
            }
            @font-face {
                font-family: 'GothamExtraLight';
                font-style: normal;
                font-display: swap;
                font-weight: 300;
                src: url(${GothExtraLightWoff2}) format('woff2');
            }
        `,
    },
    MuiGrid: {
        variants: [
            {
                props: { variant: 'container' },
                style: {
                    maxWidth: '1400px',
                    width: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
            },
        ],
    },
    MuiTextField: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                '& input': {
                    color: theme.palette.white.main,
                    backgroundColor: theme.palette.black.pure,
                    fontSize: '14px',
                },
                '& .MuiInputLabel-root': {
                    color: theme.palette.white.main,
                    fontSize: '14px',
                    '&.Mui-focused': {
                        color: theme.palette.white.main,
                    },
                }, // styles the label
                '& .MuiOutlinedInput-root': {
                    ':hover': {
                        '& > fieldset': {
                            borderColor: theme.palette.white.main,
                        },
                    },
                    '& > fieldset': { borderColor: theme.palette.blue.main },
                    '&.Mui-focused': {
                        '& > fieldset': { borderColor: theme.palette.white.main, color: theme.palette.white.main },
                    },
                },
            }),
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: ({ ownerState, theme }) => ({
                backgroundColor: theme.palette.black.main,
                borderRadius: '20px',
                boxShadow: '0 1px 1.1px hsl(0deg 0% 0%/48%),0.1px 5.2px 5.9px -1.2px hsl(0deg 0% 0%/48%),0.2px 20px 22.5px -2.5px hsl(0deg 0% 0%/48%)',
                color: theme.palette.white.main,
                '& .MuiDialogContentText-root': {
                    color: theme.palette.white.main,
                },
            }),
        },
    },
}

export default components
