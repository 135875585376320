import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isMenuOpen: false,
    isNavOpen: false,
    isCurrentTab: 'home',
}

export const menu = createSlice({
    name: 'globals',
    initialState,
    reducers: {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        menuToggle: (state, action) => {
            state.isMenuOpen = JSON.parse(action.payload)
        },
        navToggle: (state, action) => {
            state.isNavOpen = JSON.parse(action.payload)
        },
        currentTab: (state, action) => {
            state.isCurrentTab = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    menuToggle,
    navToggle,
    currentTab,
} = menu.actions

export default menu.reducer
