import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    extendAuctionTime: 3,
    extendAuctionThresholdTime: 3,
    startAuctionTime: null,
    auctionStarted: false
}

export const globalsSlice = createSlice({
    name: 'globals',
    initialState,
    reducers: {
        globalsState: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
        },
        setStartAuctionTime: (state, action) => {
            state.startAuctionTime = JSON.parse(action.payload)
        },
        setAuctionActive: (state, action) => {
            state.auctionStarted =  action.payload || true
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    globalsState,
    setStartAuctionTime,
    setAuctionActive
} = globalsSlice.actions

export default globalsSlice.reducer
