import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Theme from './src/theme/Theme'
import { myFirebase } from './src/system/firebase/index'
import { store } from './src/system/redux/store'

const Default = ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    // Initialise Firestore connection
    const initFirebase = myFirebase
    if ( initFirebase ) {
        // Firestore has been Initialised and connection successful
    }

    return (
        <Provider store={store}>
            <ThemeProvider theme={Theme}>
                {/* TODO: Update this */}
                <CssBaseline>
                    {element}
                </CssBaseline>
            </ThemeProvider>
        </Provider>
    )
}

export default Default
