import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    groupId: 'BE0YWrQI1J3wcF88c1p4',
    auctionId: 'GeGvPtkPXMIeCloRt0ST',
}

export const auctionSlice = createSlice({
    name: 'auction',
    initialState,
    reducers: {
        auctionState: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    auctionState,
} = auctionSlice.actions

export default auctionSlice.reducer
