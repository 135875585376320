// https://mui.com/customization/typography/
import palette from './palette'
// TODO: Tidy this up

const typography = {
    fontFamily: 'GothamMedium',
    fontSize: '14px',
    fontWeight: 500,
    h1: {
        fontFamily: 'GothamExtraLight',
        fontSize: '65px',
        fontWeight: 300,
    },
    h2: {
        fontFamily: 'GothamRegular',
        fontWeight: 400,
        fontSize: '35px',
        letterSpacing: '1px',
    },
    h3: {
        fontFamily: 'GothamRegular',
        fontWeight: 400,
        fontSize: '25px',
        letterSpacing: '1px',
    },
    h4: {
        fontFamily: 'GothamRegular',
        fontWeight: 400,
        fontSize: '20px',
        letterSpacing: '1px',
    },
    h5: {
        fontFamily: 'GothamMedium',
        fontWeight: 400,
        fontSize: '25px',
        lineHeight: '1.125',
    },
    h6: {
        fontFamily: 'GothamMedium',
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: '1.125',
    },
    body1: {
        fontFamily: 'GothamRegular',
        fontSize: '16px',
        fontWeight: 400,
    },
    accordion: {
        fontFamily: 'GothamBold',
        fontSize: '12px',
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    content: {
        fontFamily: 'GothamRegular',
        h2: {
            fontSize: '35px',
            fontWeight: 400,
            lineHeight: '40px',
        },
        h3: {
            fontSize: '30px',
            fontWeight: 400,
            lineHeight: '40px',
        },
        p: {
            fontSize: '16px',
            lineHeight: '28px',
        },
        a: {
            '&.orange': {
                textDecorationColor: palette.orange.dark,
                ':hover': {
                    textDecorationColor: palette.orange.main,
                },
            },
            color: palette.white.main,
            textDecoration: '2px solid underline',
            textDecorationColor: palette.blue.main,
            textUnderlineOffset: '5px',
            ':hover': {
                filter: 'brightness(125%)',
                textDecorationColor: palette.blue.lite,
            },
        },
        small: {
            fontSize: '12px',
        },
    },
    footer: {
        fontFamily: 'GothamRegular',
        fontSize: '12px',
        fontWeight: 400,
    },
    link: {
        fontFamily: 'GothamMedium',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'uppercase',
        a: {
            color: palette.blue.lite,
            textDecoration: 'none',
            ':hover': {
                color: palette.white.main,
            },
        },
    },
    link2: {
        fontFamily: 'GothamMedium',
        fontSize: '14px',
        fontWeight: 500,
        a: {
            color: palette.white.main,
            textDecoration: '2px solid underline',
            textDecorationColor: palette.white.main,
            textUnderlineOffset: '5px',
            ':hover': {
                textDecorationColor: palette.blue.main,
            },
        },
    },
    table: {
        fontFamily: 'GothamRegular',
        fontSize: '14px',
    },
    timer: {
        color: palette.blue.lite,
        fontFamily: 'GothamExtraLight',
        fontSize: '34px',
        fontWeight: 400,
        lineHeight: '40px',
    },
    timerSmall: {
        color: palette.grey.lighter,
        fontFamily: 'GothamMedium',
        fontSize: '9px',
        fontWeight: 400,
        textTransform: 'uppercase',
    },
}

export default typography
