import { configureStore } from '@reduxjs/toolkit'
import Auth from './reducers/auth'
import Globals from './reducers/globals'
import Menu from './reducers/menu'
import Auction from './reducers/auction'
import User from './reducers/user'
import Dialog from './reducers/dialog'

export const store = configureStore({
    reducer: {
        Auth,
        Globals,
        Menu,
        Auction,
        User,
        Dialog,
    },
})
