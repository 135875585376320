// https://mui.com/customization/palette/

const palette = {
    background: {
        default: '#121212',
    },
    black: {
        main: '#121212',
        pure: '#000000',
        contrastText: '#fff',
    },
    white: {
        main: '#FFFFFF',
        off: '#E0E0E0',
    },
    blue: {
        main: '#0065FF',
        lite: '#009dff',
    },
    orange: {
        main: '#FF9900',
        dark: '#EF6D01',
    },
    grey: {
        main: '#D6D3CE',
        menu: '#2C2C2C',
        lighter: '#bdbdbd',
        lite: '#323232',
        dark: '#1D1D1D',
        darkest: '#4E5055',
        button: '#373737',
        accordion: '#262626',
    },
    clay: {
        main: '#AFA99F',
        dark: '#AEA89E',
    },
    red: {
        main: '#C20122',
        lite: '#CE132D',
    },
    green: {
        main: '#41BB52',
    }
}

export default palette
